module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-7H63QJCZ0L"],"pluginConfig":{"head":false,"anonymize":true,"cookie_name":"gatsby-gdpr-google-analytics"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-gdpr-cookies/gatsby-browser.js'),
      options: {"plugins":[],"googleAnalytics":{"trackingId":"G-7H63QJCZ0L","anonymize":true},"environments":["production","development"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/vercel/path0","commonmark":false,"JSFrontmatterEngine":false,"engines":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Zenity website","short_name":"Zenity","icon":"/vercel/path0/src/resources/favicon.png","icons":[{"src":"/vercel/path0/src/resources/favicons/favicon-16x16.png","sizes":"16x16","type":"image/png"},{"src":"/vercel/path0/src/resources/favicons/favicon-32x32.png","sizes":"32x32","type":"image/png"},{"src":"/vercel/path0/src/resources/favicons/favicon-192x192.png","sizes":"192x192","type":"image/png"},{"src":"/vercel/path0/src/resources/favicons/favicon-512x512.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"17c335187c879a6db710048569c6613b"},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
